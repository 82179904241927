import Cookies from "js-cookie"

import { addAlert } from "app-store-v2/app/actions"

import getSpecialPaths from "./special-paths"

export const gtmCallback = (fn) => {
  return (id) => {
    if (sl.config.google_gtm_id != id) {
      return
    }
    fn()
  }
}

export const IS_NA = () => ["us", "ca"].indexOf(sl.config.site_country) !== -1
export const IS_JEWLR = () => sl.config.organization === "jewlr4"
export const IS_PORTAL = () => sl.config.organization === "portal"
export const IS_DEVELOPMENT = () => sl.config.env === "development"
export const IS_STAGING = () => sl.config.env === "staging"
export const IS_PRODUCTION = () => sl.config.env === "production"
export const IS_US = () => sl.config.site_country === "us"
export const IS_CA = () => sl.config.site_country === "ca"
export const IS_AU = () => sl.config.site_country === "au"
export const IS_UK = () => sl.config.site_country === "uk"
export const IS_EUR = () =>
  ["eu", "es", "fr", "de", "nl"].indexOf(sl.config.site_country) !== -1
// Check if it's in the english language
export const IS_ENG = () =>
  sl.locale !== "fr-FR" &&
  (sl.config.env == "staging" ||
    sl.config.env == "production" ||
    sl.locale.includes("en"))

export const jewlr_t = (term, addToBagExp = null) => {
  if (!term || term == "") {
    return ""
  }

  // AddToBag A/B Test
  if (addToBagExp === 1) {
    switch (term) {
      case "cart":
        return "bag"
      case "Cart":
        return "Bag"
      case "CART":
        return "BAG"
      default:
        return term
    }
  }

  const terms = require(`i18n/${sl.locale}`).default

  const lookup = term.replace("terms.", "")

  const result = terms[lookup]

  return result || `Missing ${sl.locale}.${term}`
}

export const reverse_t = (term, targetLocale) => {
  let tl = targetLocale
  if (!tl) {
    tl = sl.locale
  }
  if (!term) return ""
  let result = term

  if (targetLocale === sl.locale) {
    return term
  }

  const localTerms = require(`i18n/${sl.locale}`).default
  const targetTerms = require(`i18n/${targetLocale}`).default
  Object.keys(localTerms).forEach((term) => {
    if (typeof localTerms[term] !== "string" || term.startsWith("number.")) {
      return
    }
    const regex = new RegExp(`\\b${localTerms[term]}\\b`, "g")
    result = result.replace(regex, targetTerms[term])
  })

  return result
}

export const getHreflangs = (path) => {
  let defaultHref = ""
  const specialPaths = getSpecialPaths[path]
  let result = Object.entries(sl.config.site_url_by_country || {})
    .filter(([country]) => !specialPaths || specialPaths.includes(country))
    .map(([country, url]) => {
      const locale = (sl.locales || {})[country.toLowerCase()]
      const localizedPath = reverse_t(path, locale)
      const href = `${url}${localizedPath}`

      if (country === "US") {
        defaultHref = href
      }

      return {
        href,
        hreflang: locale,
      }
    })

  if (defaultHref) {
    result.push({
      href: defaultHref,
      hreflang: "x-default",
    })
  }

  return result
}

export const machinize = (text) => {
  return text.replace(/[^a-zA-Z]/g, "")
}

export const isMobile =
  typeof window !== "undefined" && window.innerWidth <= 767

export const isTablet =
  typeof window !== "undefined" &&
  window.innerWidth > 767 &&
  window.innerWidth < 1024

export const isDesktop =
  typeof window !== "undefined" && window.innerWidth >= 1024

export const perfMark = (markName) => {
  if (
    typeof performance !== "undefined" &&
    typeof performance.mark !== "undefined"
  ) {
    performance.mark(markName)
  }
}

export const setFSUserVar = (key, value) => {
  if (typeof FS !== "undefined") {
    try {
      let userVars = {}
      userVars[key] = value
      FS.setUserVars(userVars)
    } catch (e) {
      console.log("Set FS User Var Error", key, value, e)
    }
  }
  sl.fullstory[key] = value
}

/**
 * Takes the hash of a pre-parsed Local storage value and reads expiration.
 * If expired, delete the LS and return 'undefined'.
 * If LS hash is !expired, return the hash.
 * @params {hash} lsHash - Content of the parsed local storage
 * @params {string} lsName - Name of the local storage object.
 */
export const validateLocalStorage = (lsHash, lsName) => {
  if (
    lsHash &&
    Object.keys(lsHash).length > 0 &&
    lsHash.expires &&
    lsHash.expires.length > 0
  ) {
    let expiration = Date.parse(lsHash.expires)
    let now = Date.now()
    if (typeof window !== "undefined") {
      if (expiration < now || typeof expiration == "undefined") {
        // is expired or expiration is undefined
        try {
          window.localStorage.clear(lsName)
          return undefined
        } catch (err) {
          return err
        }
      } else {
        return lsHash
      }
    } else {
      console.error("setLSExpiry window not loaded")
    }
  } else {
    // Doesn't need to be on unless you're debugging
    // console.log("validateLocalStorage - Expiration data failed")
    return undefined
  }
}

/**
 * Takes the hash and adds expriation value and creates a local storage object.
 * @params {hash} options.isString - Flag to indicate lsHash is already already stringfied.
 * @params {int} expires - Days until it local storage will expire. If no value is provided it defaults 30.
 * @params {string} lsName - Name of the local storage.
 * @params {hash} lsHash - Hash of data to be stringified and stored in local storage.
 */
export const setLocalStorage = (lsHash, lsName, expires = 30, options = {}) => {
  if (typeof expires == "undefined") {
    expires = 30
  }
  let hash = lsHash
  let futureDate = new Date()
  futureDate.setDate(futureDate.getDate() + expires)
  hash["expires"] = futureDate
  if (typeof window !== "undefined") {
    try {
      if (options.isString) {
        window.localStorage.setItem(lsName, hash)
      } else {
        window.localStorage.setItem(lsName, JSON.stringify(hash))
      }
      return 1
    } catch (err) {
      return err
    }
  } else {
    console.error("setLocalStorage window not loaded")
  }
}

export const copyToClipboard = (e, input) => {
  // Copy text to clipboard with click of the button.
  // Takes the event and element as arguments.
  // Browser support for Chrome 43+, Firefox 42+, Safari 10+, iOS Safari, Edge and Internet Explorer 10+.
  // Checks if device is iOS (Chrome on iOS falls into this category)
  const detectIOS = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS"
    }
    return false
  }
  // Copy method only works for iOS
  const iosCopyToClipboard = (el) => {
    let oldContentEditable = el.contentEditable
    let oldReadOnly = el.readOnly
    let range = document.createRange()

    el.contentEditable = true
    el.readOnly = false
    range.selectNodeContents(el)

    let s = window.getSelection()
    s.removeAllRanges()
    s.addRange(range)

    el.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable
    el.readOnly = oldReadOnly

    document.execCommand("copy")
  }
  // Main copy method utilizing browser copy to clipboard function.
  const copyToClipboard = (e, input) => {
    const text = input.value
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      e.target.innerHTML = "Copied!"
      return window.clipboardData.setData("Text", text)
    } else if (detectIOS() === "iOS") {
      // iOS copy method specific
      iosCopyToClipboard(input)
      e.target.innerHTML = "Copied!"
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      // Copy method for everything else
      input.select()
      try {
        e.target.innerHTML = "Copied!"
        return document.execCommand("copy") // Security exception may be thrown by some browsers.
      } catch (ex) {
        window.alert("Copy Failed")
        console.warn("Copy to clipboard failed.", ex)
        return false
      } finally {
        e.target.innerHTML = "Copied!"
      }
    } else {
      console.log("Browser Copy and Paste isn't supported")
      window.alert(
        "Your browser is not supported. Please share this link by copy and paste."
      )
    }
  }

  return copyToClipboard(e, input)
}

export const setJewlrCustomerCookie = () => {
  Cookies.set("jewlr_customer", true, { expires: 365, secure: true })
}

export const checkJewlrCustomer = () => {
  const allCookies = Cookies.get()
  for (const cookieName in allCookies) {
    if (
      (cookieName.startsWith("J") && allCookies[cookieName] === "true") ||
      (cookieName == "jewlr_customer" && allCookies[cookieName] === "true")
    ) {
      return true
    }
  }
  return false
}

export const sendUserData = (data, callback = null) => {
  perfMark("app:send_user_data:start")
  let queryString = ""
  if (typeof data === "undefined") {
    queryString = window.location.search
  } else {
    const URLparams = new URLSearchParams(Object.entries(data))
    queryString = "?" + URLparams.toString()
  }

  Cookies.remove("jwt")
  Cookies.remove("jwt_options")
  Cookies.remove("access_token")
  Cookies.remove("refresh_token")
  Cookies.remove("token_expiry")

  window.api
    .get("/api/v10/user_data" + queryString)
    .then((apiResult) => {
      if (!apiResult || !apiResult.data) {
        return
      }
      const result = apiResult.data

      if (result.nothing) {
        return
      }
      if (result.cart_token && !isTestMode) {
        dataLayer.push({
          cartToken: result.cart_token,
          email: result.email,
          event: "user-data-loaded",
          hashedEmail: result.hashed_email,
          hashedUserGUID: result.hashed_user_guid,
          jewlrCustomer: checkJewlrCustomer(),
          userGuid: result.guid,
        })
      }

      if (result.first_time_visitor && !isTestMode) {
        dataLayer.push({
          event: "first-time-visitor",
        })
      }

      // Note: prevent dispatch actions too soon
      // sendUserData fires very soon (A bit after DOM connected and before downloading JS chunks and hydration process)
      // appStoreV2.dispatch(setExperiment(result.campaign))
      // appStoreV2.dispatch(updatePointsAccount(result.points_info))
      // appStoreV2.dispatch(setWishlistCount(result.counts.wishlist))
      // appStoreV2.dispatch(setCart({ count: result.counts.cart }))

      if (result.page_notification) {
        window.setTimeout(function () {
          appStoreV2.dispatch(addAlert(result.page_notification))
          submit_ga_event_click(
            "Notification",
            "show",
            result.page_notification.name,
            null,
            null,
            true
          )
        }, result.page_notification.delay * 1000)
      }
      if (result.everywhere_notification) {
        window.setTimeout(function () {
          window.appStore.dispatch(addAlert(result.everywhere_notification))
          submit_ga_event_click(
            "Notification",
            "show",
            result.everywhere_notification.name,
            null,
            null,
            true
          )
        }, result.everywhere_notification.delay * 1000)
      }
      if (callback) {
        callback()
      }
      perfMark("app:send_user_data:finish")
    })
    .catch((error) => console.error("func sendUserData Error: ", error))
}

const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase()

export const titleize = (str) =>
  str
    .split(" ")
    .map((x) => capitalizeFirstLetter(x))
    .join(" ")

export const isTestMode = Cookies.get("test_mode") === "true"

export const countryMap = (country) => {
  if (!country) return ""
  if (country.toUpperCase() == "GB") return "UK"
  return country
}

const consoleDebug = (fn) => {
  if (typeof console.debug !== "undefined") {
    console.debug(fn())
  }
}

export const retry = (fn, retriesLeft = 5, interval = 500, retryMessage = "") =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            console.error(
              `Maximum retries exceeded, retryMessage: ${retryMessage}. Reloading page...`
            )
            window.location.reload()
            return
          }
          // Passing on "reject" is the important part
          consoleDebug(
            () =>
              `Trying request, retryMessage: ${retryMessage}, retriesLeft: ${
                retriesLeft - 1
              }`
          )
          retry(fn, retriesLeft - 1, interval, retryMessage).then(
            resolve,
            reject
          )
        }, interval)
      })
  })

export const getMetaDescription = () =>
  `Jewlr ${jewlr_t("terms.specialize")}s in selling high quality ${jewlr_t(
    "terms.personalized"
  )} ${jewlr_t(
    "terms.jewelry"
  )}, made just for you. Create the perfect gift or ${jewlr_t(
    "terms.personalize"
  )} something for yourself. Enjoy Free ${jewlr_t(
    "terms.Shipping"
  )} and 99-Day Returns!`
